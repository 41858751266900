import React, {useState, useEffect} from 'react';
import {Pie, Bar} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title} from 'chart.js';
import {refreshToken} from "../services/authService";
import Loader from "./Loader";
import {StartsEndsCurrentMonth} from '../utils/Date'
import {getConfig} from "../config";


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);  // Регистрация элементов, необходимых для Pie Chart

const BalancePieChart = () => {
    const [totalData, setTotalData] = useState({});
    const [expensesData, setExpensesData] = useState({});
    const [incomesData, setIncomesData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, endDate] = StartsEndsCurrentMonth();
    const {API_URL} = getConfig();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseTotalExpenses = await fetch(
                    `${API_URL}/api/v1/expenses/total?startDate=${startDate}&endDate=${endDate}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );
                if (responseTotalExpenses.status === 401) {
                    refreshToken()
                }
                if (!responseTotalExpenses.ok) {
                    throw new Error('Ошибка получения данных');
                }
                const resultTotalExpenses = await responseTotalExpenses.json();

                const labels = [resultTotalExpenses.expenses.title];
                const values = [resultTotalExpenses.expenses.amount];


                const responseTotalIncomes = await fetch(
                    `${API_URL}/api/v1/incomes/total?startDate=${startDate}&endDate=${endDate}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );
                if (responseTotalIncomes.status === 401) {
                    refreshToken()
                }
                if (!responseTotalIncomes.ok) {
                    throw new Error('Ошибка получения данных');
                }
                const resultTotalIncomes = await responseTotalIncomes.json();

                labels.push(resultTotalIncomes.incomes.title);
                values.push(resultTotalIncomes.incomes.amount);

                setTotalData({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            // backgroundColor: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
                            // hoverBackgroundColor: [
                            //     'rgba(255, 99, 132, 1)',
                            //     'rgba(54, 162, 235, 1)',
                            //     'rgba(255, 206, 86, 1)',
                            // ],
                            hoverBorderColor: ['#1F3C75', '#1F3C75', '#1F3C75'],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.8)',
                                'rgba(54, 162, 235, 0.8)',
                                'rgba(255, 206, 86, 0.8)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 0)',
                                'rgba(54, 162, 235, 0)',
                                'rgba(255, 206, 86, 0)',
                            ],
                            borderWidth: 1,
                        }
                    ]
                });

                const responseExpenses = await fetch(
                    `${API_URL}/api/v1/expenses?startDate=${startDate}&endDate=${endDate}&category=all`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );
                if (responseExpenses.status === 401) {
                    refreshToken()
                }
                if (!responseExpenses.ok) {
                    throw new Error('Ошибка получения данных');
                }

                const resultExpenses = await responseExpenses.json();

                const expensesLabels = resultExpenses.expenses.map(item => item.title);
                const expensesValues = resultExpenses.expenses.map(item => item.amount);

                console.log(expensesLabels);
                console.log(expensesValues);

                setExpensesData({
                    labels: expensesLabels,
                    datasets: [
                        {
                            label: 'Расходы',
                            data: expensesValues,
                            hoverBorderColor: ['#1F3C75', '#1F3C75', '#1F3C75'],
                            backgroundColor: 'rgba(255, 99, 132, 0.8)',
                            borderColor: 'rgba(255, 99, 132, 0)',
                            borderWidth: 1,
                        }
                    ]
                });

            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [startDate, endDate, API_URL]);

    if (loading) return <Loader/>
    if (error) return <p>Ошибка: {error}</p>;

    const optionsPie = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
    };
    const optionsBar = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        scales: {
            y: {
                beginAtZero: true
            }
        },
        title: {
            display: true,
            text: 'Категории расходов',
        },

    };

    return (
        <div>
            <Pie data={totalData} options={optionsPie}/>
            <Bar data={expensesData} options={optionsBar}/>
            {/*<Pie data={incomesData} options={options}/>*/}
        </div>
    )
};

export default BalancePieChart;